import { defineStore } from 'pinia'
import destr from 'destr'
import { jwtDecode } from 'jwt-decode'
// @ts-ignore
import jwtEncode from 'jwt-encode'
import { event, set } from 'vue-gtag'
import useCountryCodes from './countryCodes'
import * as Sentry from '@sentry/vue'

import { clearAuthToken, setAuthToken } from '../plugins/httpClient'
import type {
  IAuthLoginForm,
  IAuthRegisterForm,
  IAuthUser,
  IAuthUserBalance,
  IAdminPermissions,
  IJwt,
  IRestorePassword,
  ISession,
  ISessionResponse,
  IUserVideoGuide,
  IAuthUserBalanceBase,
  IUpdateUserPayload
} from '../models/AuthUser'
import { useYandexMetrika } from '../plugins/yandex-metrika/index'

import authApi from '../api/auth.api'
import jivoApi from '../api/jivo.api'

import authAdminApi from '../api/auth.admin.api'

import { lastSymbToLower } from '../utils/strings'
import { isUsdFamily, isRubFamily, symbolToURLsymbol } from '../utils/currency'
import { computed, reactive, ref } from 'vue'

import { AxiosError } from 'axios'
import { client } from '../plugins/platform'
import { screen } from '../plugins/screen'
import useCurrencyStore from '../stores/currency'

const sessionName = import.meta.env.PUBLIC_SESSION_NAME

let token_checker: NodeJS.Timeout

function getUserDataAsDefaults(): IAuthUser {
  return {
    about: undefined,
    address: undefined,
    account_type: null,
    birth_date: undefined,
    country: undefined,
    dt_delete: undefined,
    email: undefined,
    first_name: undefined,
    ga_uid: null,
    has_2fa: false,
    is_borrowing: false,
    is_clicked_invest: false,
    is_clicked_trade: false,
    is_getting_crypto: undefined,
    is_investing: undefined,

    last_name: undefined,
    locale: 'en',
    phone_number: undefined,
    referral_code: undefined,
    settings: undefined,
    telegram_notifications: false,
    social_networks: undefined,
    source_of_funds: undefined,
    nickname: undefined,
    user_number: '',
    ui_settings: (() => ({
      isTradePro: false,
      kycModalIsHide: false
    }))(),
    is_maintenance: false,
    default_currency_id: undefined,
    is_technical: false,
    otc_deals: false,
    is_swift_provider: false,
    pending_payments_count: 0,
    can_transfer_sbp: false
  }
}

function getAdminPermissionsAsDefaults(): IAdminPermissions {
  return {
    can_dw_currencies: (() => ['USD.R', 'USD.K', 'RUB.R', 'AED'])(),
    has_access_to_all_wl: false,
    has_read_access_to_analytics: false,
    has_read_access_to_trades: false,
    has_read_access_to_ledgers: false,
    has_read_access_to_balances: false,
    has_write_access_to_balances: false,
    has_read_access_to_deposits: false,
    has_write_access_to_deposits: false,
    has_read_access_to_emails: false,
    has_write_access_to_emails: false,
    has_read_access_to_kyc: false,
    has_write_access_to_kyc: false,
    has_read_access_to_kyc_kgz: false,
    has_write_access_to_kyc_kgz: false,
    has_read_access_to_p2p: false,
    has_write_access_to_p2p: false,
    has_read_access_to_operator: false,
    has_write_access_to_operator: false,
    has_read_access_to_options: false,
    has_write_access_to_options: false,
    has_read_access_to_users: false,
    has_write_access_to_users: false,
    has_read_access_to_roles: false,
    has_write_access_to_roles: false,
    has_read_access_to_withdrawals: false,
    has_write_access_to_withdrawals: false,
    has_read_access_to_swift: false,
    has_write_access_to_swift: false,
    has_read_access_to_borrowings: false,
    has_write_access_to_borrowings: false,
    has_read_access_to_visa_withdrawals: false,
    has_write_access_to_visa_withdrawals: false,
    has_read_access_to_investment: false,
    has_write_access_to_investment: false,
    has_read_access_to_crypto_terminals: false,
    has_write_access_to_crypto_terminals: false,
    has_write_access_to_application_settings: false,
    has_read_access_to_application_settings: false,
    has_read_access_to_blocked_balances: false,
    has_write_access_to_blocked_balances: false,
    has_read_access_to_bank_transfers: false,
    has_write_access_to_bank_transfers: false,
    has_read_access_to_own_referrals_ledgers: false,
    has_read_access_to_own_referrals_users: false,
    has_read_access_to_admins: false,
    has_read_access_to_contracts: false,
    has_write_access_to_contracts: false,
    has_read_access_to_wl_settings: false,
    has_write_access_to_wl_settings: false,
    has_read_access_to_wl_services: false,
    has_read_access_to_changelog: false,
    has_write_access_to_referral_payments: false,
    has_write_access_to_auto_order_grid: false,
    has_read_access_to_swift_providers: false,
    has_write_access_to_swift_providers: false,
    has_read_access_to_clearing: false,
    has_write_access_to_clearing: false
  }
}

function getSessionAsDefaults(): ISession {
  return {
    token: undefined,
    refresh_token: undefined,
    groups: (() => [])(),
    data: undefined,
    access_token: undefined
  }
}

export const useAuthStore = defineStore('auth', () => {
  /* state */
  const user = reactive<IAuthUser>(getUserDataAsDefaults())
  const currencyStore = useCurrencyStore()

  const adminPermissions = reactive<IAdminPermissions>(
    getAdminPermissionsAsDefaults()
  )

  const balances = ref<IAuthUserBalance[]>([])
  const isLoadingFirstData = ref(true)

  const isAuthenticated = computed(() => {
    return user.email !== undefined
  })

  const session = reactive<ISession>(getSessionAsDefaults())
  const videoGuides = ref<IUserVideoGuide[]>([])

  const uiSettings = computed(() => {
    return user.ui_settings
  })

  const updateUiSettings = async (
    payload: Partial<IAuthUser['ui_settings']>
  ) => {
    await authApi.saveUiSettings(Object.assign(user.ui_settings, payload))

    await fetchUser()
  }

  /* getters */

  const availableBalances = computed(() => {
    const activeCurrencyBalances = currencyStore.items.reduce(
      (acc: IAuthUserBalance[], currency) => {
        const balance = balances.value.find(b => b.symbol === currency.symbol)

        if (balance) {
          acc.push(balance)
        }

        return acc
      },
      []
    )

    balances.value.forEach(b => {
      const exist = activeCurrencyBalances.find(ac => ac.symbol === b.symbol)

      if (!exist) {
        activeCurrencyBalances.push(b)
      }
    })

    // не отображаем default и пустые available балансы
    return activeCurrencyBalances.filter(b => !b.is_default && b.available)
  })

  const borrowingBalances = computed(() =>
    availableBalances.value.reduce((acc: IAuthUserBalance[], balance) => {
      if (balance?.borrowed && balance.borrowed > 0) {
        acc.push(balance)
      }

      return acc
    }, [])
  )

  const defaultCurrencyBalance = computed(() => {
    const foundBalance = balances.value.find(({ is_default }) => is_default)

    if (!foundBalance) return

    return foundBalance
  })

  const isAdmin = computed(() => {
    return !!session.groups?.find(g => g === 'admin')
  })

  const userFromRussia = computed(() => {
    const countryCodes = useCountryCodes()

    return countryCodes.getCountryCodeByName(user.country) === 'RU'
  })

  const userFullName = computed(() => {
    const fullArray: string[] = []

    if (user?.first_name !== undefined && user.first_name?.length > 0) {
      fullArray.push(user.first_name)
    }

    if (user?.last_name !== undefined && user.last_name?.length > 0) {
      fullArray.push(user.last_name)
    }

    return fullArray.join(' ')
  })

  function getBalanceBySymbol(symbol: string | undefined | null) {
    const defaultBal = {
      available: 0,
      blocked: 0,
      pending: 0
    }

    if (!symbol) {
      return defaultBal
    }

    return (
      balances.value.find(currency => currency.symbol === symbol) || defaultBal
    )
  }

  function groupsAllowed(required_groups: string[]) {
    if (!required_groups || required_groups.length === 0) return false

    return required_groups.some(group_name => {
      if (!session?.groups?.length) return false

      return session.groups.indexOf(group_name) > -1
    })
  }

  /* actions */

  function $reset() {
    Object.assign(user, getUserDataAsDefaults())
    Object.assign(adminPermissions, getAdminPermissionsAsDefaults())
    Object.assign(session, getSessionAsDefaults())
  }

  function setUser(user_: Partial<IAuthUser>) {
    user_.ui_settings = Object.assign(
      getUserDataAsDefaults().ui_settings,
      user_.ui_settings
    )

    Object.assign(user, user_)

    Sentry.setUser({
      id: String(user.ga_uid),
      email: user.email,
      username: `${user.first_name} ${user.last_name}`,
      country: user.country,
      locale: user.locale,
      platform_is_mobile: client.is.mobile,
      screen_height: screen.height,
      screen_width: screen.width
    })

    if (__IS_APP__) {
      set({
        user_id: user_.ga_uid
      })

      setJivoUser()

      const yandexMetrika = useYandexMetrika()

      yandexMetrika.setUserID(user_.ga_uid || '')

      const membership = session.groups?.length ? 'is_operator' : 'is_user'

      yandexMetrika.userParams({
        UserID: user_.ga_uid,
        membership
      })
    }

    handleTokenExpiration().then()
  }

  function setGAUserRole(session_: ISession) {
    if (__IS_APP__) {
      const membership = session_.groups?.length ? 'is_operator' : 'is_user'

      set({
        dimension1: membership
      })
    }
  }

  function setSession(session_: ISession) {
    Object.assign(session, session_)

    setAuthToken(session_.token)
    storeSession()
  }

  async function auth(session_: ISession) {
    setSession(session_)

    await fetchUserInfo()
  }

  async function login(payload: IAuthLoginForm, inAdmin = false) {
    if (payload.email === undefined || payload.password === undefined) {
      return
    }

    let result

    if (__IS_ADMIN_PANEL__) {
      result = await authAdminApi.login(payload)
    }

    if (__IS_APP__) {
      result = await authApi.login(payload)
    }

    if (!result) {
      console.error('!!!login result is undefined')

      return
    }

    if (inAdmin && !result.groups?.includes('admin')) {
      throw new Error('Нет прав администратора')
    }

    if (result !== undefined) {
      const session = parseSession(result)

      setGAUserRole(session)

      if (__IS_APP__) {
        event('login', { method: 'Enter Login/Pass' })

        const yandexMetrika = useYandexMetrika()

        yandexMetrika.reachGoal('login')
      }

      await auth(session)
    }
  }

  async function register(payload: IAuthRegisterForm) {
    await authApi.register(payload)
    event('sign_up', { method: 'Enter Login/Pass' })

    event('start_registration', {
      event_category: 'registration'
    })

    const yandexMetrika = useYandexMetrika()

    yandexMetrika.reachGoal('start_registration')
  }

  async function refreshToken() {
    const data = await authApi.refreshToken(session.refresh_token)

    if (data !== undefined) {
      const session = parseSession(data)

      setSession(session)
    }
  }

  async function handleTokenExpiration() {
    if (!isAuthenticated.value) {
      return
    }

    token_checker && clearInterval(token_checker)

    const refresh = async () => {
      if (!session.refresh_token || !session.data?.exp) {
        return
      }

      const expiry_time = session.data.exp * 1000 - 60000

      const now = Date.now()

      if (expiry_time < now) {
        await refreshToken()
      }
    }

    await refresh()

    token_checker = setInterval(() => {
      refresh().then()
    }, 5000)
  }

  async function fetchUserInfo() {
    isLoadingFirstData.value = true

    try {
      if (__IS_APP__) {
        await Promise.all([
          fetchUser(),
          fetchUserBalances(),
          fetchUserVideoGuides()
        ])
      }

      if (__IS_ADMIN_PANEL__) {
        await Promise.all([fetchUser(), fetchAdminPermissions()])
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.warn(
          'fetchUser error',
          error.message !== undefined ? error.message : 'unexpected error'
        )
      } else {
        console.error('fetchUser unexpected error', error)
      }
    } finally {
      isLoadingFirstData.value = false
    }
  }

  async function fetchUser() {
    let user

    try {
      if (__IS_ADMIN_PANEL__) {
        user = await authAdminApi.fetchUser()
      }

      if (__IS_APP__) {
        user = await authApi.fetchUser()
      }

      if (user !== undefined) {
        setUser(user)
      }
    } catch (e) {
      /**/
    }
  }

  function setJivoUser() {
    const jivoSecretKey = import.meta.env.PUBLIC_JIVO_SECRET_KEY
    const gaUid = user.ga_uid

    if (!gaUid || !jivoSecretKey) return

    const encodedJwt = jwtEncode(
      { id: gaUid, secret: jivoSecretKey },
      jivoSecretKey
    )

    jivoApi.setUserToken(encodedJwt)

    jivoApi.updateContactInfo({
      name: user.first_name,
      phone: user.phone_number,
      email: user.email
    })
  }

  function normalizeUserBalances(balances_?: IAuthUserBalanceBase[]) {
    if (!balances_) return []

    return balances_.map((item: IAuthUserBalanceBase) => {
      const is_usd_family = isUsdFamily(item.symbol)
      const is_rub_family = isRubFamily(item.symbol)

      return {
        ...item,
        formated_symbol:
          is_usd_family || is_rub_family
            ? lastSymbToLower(item.symbol)
            : item.symbol,
        is_usd_family,
        is_rub_family,
        symbol_url: symbolToURLsymbol(item.symbol)
      }
    })
  }

  async function fetchUserBalances() {
    try {
      const balances_ = await authApi.fetchUserBalances()

      //.slice(-2)

      balances.value = normalizeUserBalances(balances_)
    } catch (e) {
      /**/
    }
  }

  function updateUserBalances(balances_: IAuthUserBalance[]) {
    balances.value = normalizeUserBalances(balances_)
  }

  async function fetchAdminPermissions() {
    try {
      const adminPermissions_ = await authApi.fetchAdminPermissions()

      if (adminPermissions_ !== undefined) {
        Object.assign(adminPermissions, adminPermissions_)
      }
    } catch (error) {
      console.error('fetchAdminPermissions error', error)
    }
  }

  async function updateUser(userData: IUpdateUserPayload) {
    await authApi.updateUser(userData)

    setUser(userData)
  }

  async function toggleTwoFactorAuth(enabled = true, code?: string) {
    user.has_2fa = await authApi.toggleTwoFactorAuth(enabled, code)
  }

  async function getTwoFactorAuthKeys() {
    return await authApi.getTwoFactorAuthKeys()
  }

  async function confirmEmail(email?: string, code?: string) {
    if (email === undefined || code === undefined) return

    const confirmResult = await authApi.confirmEmail({
      email,
      code: code.replace(/\D/g, '').trim()
    })

    event('complete_registration', {
      event_category: 'registration'
    })

    const yandexMetrika = useYandexMetrika()

    yandexMetrika.reachGoal('complete_registration')

    return confirmResult
  }

  async function resendConfirmEmail(email?: string) {
    if (email === undefined) return

    await authApi.resendConfirmEmail(email)
  }

  async function changePassword(password: string) {
    if (!session.access_token) return

    await authApi.changePassword({
      access_token: session.access_token,
      password
    })
  }

  async function forgotPassword(email?: string) {
    if (email === undefined) return

    return await authApi.forgotPassword(email)
  }

  async function restorePassword(form: IRestorePassword) {
    form.code = form.code.replace(/\D/g, '').trim()

    return await authApi.restorePassword(form)
  }

  function logout() {
    if (!session.access_token) return

    authApi.logout({ access_token: session.access_token }).then()

    $reset()

    localStorage.removeItem(sessionName)

    clearAuthToken()
  }

  function parseSession(session_: ISessionResponse): ISession {
    return {
      token: session_.id_token,
      access_token: session_.access_token,
      refresh_token: session_.refresh_token,
      groups: session_.groups || session.groups,
      data: jwtDecode<IJwt>(session_.access_token)
    }
  }

  function storeSession() {
    const data = JSON.stringify(session)

    localStorage.setItem(sessionName, data)
  }

  function restoreSession(): ISession {
    let session_ = destr<ISession>(localStorage.getItem(sessionName))

    if (!session_) {
      session_ = getSessionAsDefaults()
    }

    setSession(session_)
    setGAUserRole(session_)

    return session_
  }

  async function setLocale(lang?: string) {
    if (lang === undefined) return

    return await authApi.setLocale(lang)
  }

  // проверка есть ли хотя бы один из переданных permissions
  function hasSomePermissions(
    permissions: keyof IAdminPermissions | Array<keyof IAdminPermissions>
  ) {
    if (typeof permissions === 'string') {
      return adminPermissions[permissions]
    } else {
      // если нашелся хотя бы один permissions, значит ок
      return permissions.some(p => {
        return adminPermissions[p]
      })
    }
  }

  async function fetchUserVideoGuides() {
    const result = await authApi.fetchUserVideoGuides()

    videoGuides.value = result.items
  }

  async function updateUserVideoGuide(payload: IUserVideoGuide) {
    await authApi.updateUserVideoGuide(payload)

    await fetchUserVideoGuides()
  }

  async function Init() {
    restoreSession()
    await fetchUserInfo()
  }

  if (process.client) {
    Init().then()
  }

  return {
    user,
    uiSettings,
    isAuthenticated,
    isLoadingFirstData,
    adminPermissions,
    balances,
    availableBalances,
    session,
    videoGuides,
    isAdmin,
    userFullName,
    userFromRussia,
    defaultCurrencyBalance,
    getBalanceBySymbol,
    groupsAllowed,
    setUser,
    setSession,
    login,
    fetchUserInfo,
    fetchUser,
    fetchUserBalances,
    register,
    updateUserBalances,
    fetchAdminPermissions,
    updateUser,
    toggleTwoFactorAuth,
    getTwoFactorAuthKeys,
    confirmEmail,
    resendConfirmEmail,
    changePassword,
    forgotPassword,
    restorePassword,
    logout,
    restoreSession,
    setLocale,
    hasSomePermissions,
    updateUserVideoGuide,
    updateUiSettings,
    borrowingBalances
  }
})
