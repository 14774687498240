import { watch } from 'vue'
import useWhiteLabelStore from '~/stores/whiteLabel'
import debounce from '~/common/src/utils/debounce'
import useWhiteLabel from './useWhiteLabel'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (callback: (domain?: string) => any) => {
  const whiteLabelStore = useWhiteLabelStore()
  const { activeWhiteLabel } = useWhiteLabel()

  watch(
    [activeWhiteLabel, () => whiteLabelStore.wlItems],
    debounce(([wl, items]) => {
      if (!items.length) return

      callback(wl?.domain)
    }),
    { immediate: true }
  )
}
