import { computed, shallowRef } from 'vue'

import { useI18n } from 'vue-i18n'

import type {
  ICurrency,
  TCurrency,
  ICurrencyCommon,
  ICurrencyAmountsType
} from '../models/Currency'
import type { IIMarket } from '../models/Market'
import { lastSymbToLower } from '../utils/strings'
import { isUsdFamily, isRubFamily, symbolToURLsymbol } from '../utils/currency'

import type {
  IFetchPublicCurrenciesParams,
  IFetchAdminCurrenciesParams
} from '../api/currency.api'

import useWatchAuthenticated from '../composables/useWatchAuthenticated'

type IPrecisions = {
  [key in TCurrency]: {
    amount: number
    balance: number
    deposit: number
    withdrawal: number
    base: number
    quote: number
    lending: number
    borrowing: number
  }
}

type TValue = string | number | undefined | null

type TFetcherParams = IFetchPublicCurrenciesParams & IFetchAdminCurrenciesParams

export default <T>(
  fetcher: (params?: TFetcherParams) => Promise<ICurrencyCommon<T>[]>,
  forceFetch: boolean = true
) => {
  const { locale } = useI18n()

  const itemsOriginal = shallowRef<ICurrencyCommon<T>[]>([])

  const items = computed(() => {
    return itemsOriginal.value.map<ICurrency<T>>(itemOriginal => {
      const is_usd_family = isUsdFamily(itemOriginal.symbol)
      const is_rub_family = isRubFamily(itemOriginal.symbol)

      return {
        ...itemOriginal,
        formated_symbol:
          is_usd_family || is_rub_family
            ? lastSymbToLower(itemOriginal.symbol)
            : itemOriginal.symbol,
        is_usd_family,
        is_rub_family,
        symbol_url: symbolToURLsymbol(itemOriginal.symbol)
      }
    })
  })

  const precisions = computed<IPrecisions>(() => {
    return {
      'n/a': {
        amount: 8,
        balance: 8,
        deposit: 8,
        withdrawal: 8,
        base: 8,
        quote: getCurrencyDecimalPlaces('BTC', 8),
        lending: 8,
        borrowing: 8
      },
      BTC: {
        amount: 8,
        balance: 8,
        deposit: 8,
        withdrawal: 8,
        base: 8,
        quote: getCurrencyDecimalPlaces('BTC', 8),
        lending: 8,
        borrowing: 8
      },
      ETH: {
        amount: 6,
        balance: 6,
        deposit: 6,
        withdrawal: 6,
        base: 6,
        quote: getCurrencyDecimalPlaces('ETH', 6),
        lending: 6,
        borrowing: 6
      },
      USDT: {
        amount: 3,
        balance: 3,
        deposit: 3,
        withdrawal: 3,
        base: 2,
        quote: getCurrencyDecimalPlaces('USDT', 2),
        lending: 3,
        borrowing: 3
      },
      'USD.R': {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 2,
        quote: getCurrencyDecimalPlaces('USD.R', 3),
        lending: 2,
        borrowing: 2
      },
      'USD.K': {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 2,
        quote: getCurrencyDecimalPlaces('USD.K', 3),
        lending: 2,
        borrowing: 2
      },
      'USD.PD': {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 2,
        quote: getCurrencyDecimalPlaces('USD.PD', 3),
        lending: 2,
        borrowing: 2
      },
      'RUB.R': {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 2,
        quote: getCurrencyDecimalPlaces('RUB.R', 2),
        lending: 2,
        borrowing: 2
      },
      'RUB.K': {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 2,
        quote: getCurrencyDecimalPlaces('RUB.K', 2),
        lending: 2,
        borrowing: 2
      },
      RUBB: {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 0,
        quote: getCurrencyDecimalPlaces('RUBB', 0),
        lending: 2,
        borrowing: 2
      },
      AED: {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 2,
        quote: getCurrencyDecimalPlaces('AED', 2),
        lending: 2,
        borrowing: 2
      },
      'AED.PD': {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 2,
        quote: getCurrencyDecimalPlaces('AED.PD', 2),
        lending: 2,
        borrowing: 2
      },
      USDC: {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 2,
        quote: getCurrencyDecimalPlaces('USDC', 2),
        lending: 2,
        borrowing: 2
      },
      TRX: {
        amount: 6,
        balance: 6,
        deposit: 6,
        withdrawal: 6,
        base: 6,
        quote: getCurrencyDecimalPlaces('TRX', 6),
        lending: 6,
        borrowing: 6
      },
      KGS: {
        amount: 0,
        balance: 0,
        deposit: 0,
        withdrawal: 0,
        base: 2,
        quote: getCurrencyDecimalPlaces('KGS', 2),
        lending: 0,
        borrowing: 0
      },
      ATOM: {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 2,
        quote: getCurrencyDecimalPlaces('ATOM', 2),
        lending: 2,
        borrowing: 2
      },
      AVAX: {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 2,
        quote: getCurrencyDecimalPlaces('AVAX', 2),
        lending: 2,
        borrowing: 2
      },
      EUR: {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 2,
        quote: getCurrencyDecimalPlaces('EUR', 2),
        lending: 2,
        borrowing: 2
      },
      TRY: {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 2,
        quote: getCurrencyDecimalPlaces('TRY', 2),
        lending: 2,
        borrowing: 2
      },
      'GLD.PD': {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 2,
        quote: getCurrencyDecimalPlaces('GLD.PD', 0),
        lending: 2,
        borrowing: 2
      },
      TON: {
        amount: 2,
        balance: 2,
        deposit: 2,
        withdrawal: 2,
        base: 2,
        quote: getCurrencyDecimalPlaces('TON', 2),
        lending: 2,
        borrowing: 2
      },
      RUBA: {
        amount: 6,
        balance: 6,
        deposit: 6,
        withdrawal: 6,
        base: 6,
        quote: getCurrencyDecimalPlaces('RUBA', 6),
        lending: 6,
        borrowing: 6
      }
    }
  })

  const activeItems = computed(() => {
    return items.value.filter(item => item.is_active)
  })

  const activeTransferItems = computed(() => {
    return activeItems.value.filter(item => item.is_transfer_enabled)
  })

  const activeDepositEnabledItems = computed(() => {
    return activeItems.value.filter(
      i =>
        i.is_deposit_enabled &&
        (i.is_fiat ||
          i.blockchain_networks.some(network => network.is_deposit_enabled))
    )
  })

  const activeWithdrawEnabledItems = computed(() => {
    return activeItems.value.filter(
      i =>
        i.is_fiat ||
        i.blockchain_networks.some(network => network.is_withdrawal_enabled)
    )
  })

  const depositItems = computed(() => {
    return items.value.filter(item => !item.is_fiat && item.is_deposit_enabled)
  })

  const availableBorrows = computed(() => {
    return items.value.filter(item => item.is_borrowing_enabled)
  })

  const availablePledge = computed(() => {
    return items.value.filter(item => item.is_pledge_enabled)
  })

  const availableLending = computed(() => {
    return items.value.filter(item => item.is_lending_enabled)
  })

  const currencyIdMap = computed(() => {
    return items.value.reduce(
      (map, item) => map.set(item.id, item),
      new Map<number | undefined | null, ICurrency<T>>()
    )
  })

  const currencySymbolMap = computed((): Map<string, ICurrency<T>> => {
    return items.value.reduce(
      (map, item) => map.set(item.symbol, item),
      new Map<TCurrency, ICurrency<T>>()
    )
  })

  const currencySymbolForURLMap = computed((): Map<string, ICurrency<T>> => {
    return items.value.reduce(
      (map, item) => map.set(item.symbol_url, item),
      new Map<string, ICurrency<T>>()
    )
  })

  const fiatItems = computed(() => {
    return items.value.filter(i => i.is_fiat)
  })

  const cryptoItems = computed(() => {
    return items.value.filter(i => !i.is_fiat)
  })

  const activeInvoiceEnabledItems = computed(() => {
    return items.value.filter(i => i.is_active && i.is_invoice_enabled)
  })

  const networks = computed(() => {
    const networksSet = new Set()

    items.value.forEach(currency => {
      currency.blockchain_networks.forEach(network => {
        networksSet.add(network.name)
      })
    })

    return Array.from(networksSet)
  })

  // получение точности по символу и типу данных
  function getPrecision(
    symbol: TCurrency | undefined,
    type: keyof ICurrencyAmountsType
  ) {
    if (!symbol) {
      return 2
    }

    return precisions.value[symbol] !== undefined
      ? precisions.value[symbol][type]
      : 8
  }

  // округление значения в нужную сторону, до нужной точности
  // подсмотрено в https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Math/round
  function roundCurrency(
    value: number,
    precision?: number,
    type: 'round' | 'ceil' | 'floor' = 'round'
  ) {
    // Если степень не определена, либо равна нулю...
    if (typeof precision === 'undefined') {
      return Math[type](value)
    }

    // Если значение не является числом, либо степень не является целым числом...
    if (isNaN(value) || precision % 1 !== 0) {
      return NaN
    }

    // Сдвиг разрядов
    const preParts = value.toString().split('e')

    const rounded = Math[type](
      +(
        preParts[0] +
        'e' +
        (preParts[1] ? +preParts[1] - precision : -precision)
      )
    )

    // Обратный сдвиг
    const postParts = rounded.toString().split('e')

    return +(
      postParts[0] +
      'e' +
      (postParts[1] ? +postParts[1] + precision : precision)
    )
  }

  // надо бы просто конструктор этих однотипных функций сделать,
  // чтобы принимал значение, валюту, тип значения, тип округления и необходимость мин и макс знаков
  // общее назначение, математическое округление
  function formatAmount(value: TValue, symbol: TCurrency = 'BTC') {
    if (value === null || value === undefined || value === '') {
      return '0'
    }

    const precision = getPrecision(symbol, 'amount')
    const numberValue = typeof value === 'string' ? parseFloat(value) : value
    const rounded = roundCurrency(numberValue, -precision, 'round')

    return rounded.toLocaleString(locale.value, {
      maximumFractionDigits: precision
    })
  }

  // размер баланса округляем вниз
  function formatBalance(value: TValue, symbol: TCurrency = 'BTC') {
    if (value === undefined || value === '' || value === null) {
      return '0'
    }

    const precision = getPrecision(symbol, 'balance')

    const numberValue = typeof value === 'string' ? parseFloat(value) : value
    const rounded = roundCurrency(numberValue, -precision, 'floor')

    return rounded.toLocaleString(locale.value, {
      maximumFractionDigits: precision
    })
  }

  // размер депозита, округляем вниз
  function formatDeposit(value: TValue, symbol: TCurrency = 'BTC'): string {
    if (value === undefined || value === '' || value === null) {
      return '0'
    }

    const precision = getPrecision(symbol, 'deposit')
    const numberValue = typeof value === 'string' ? parseFloat(value) : value
    const rounded = roundCurrency(numberValue, -precision, 'floor')

    return rounded.toLocaleString(locale.value, {
      maximumFractionDigits: precision
    })
  }

  // размер вывода, округляем вниз
  function formatWithdrawal(value: TValue, symbol: TCurrency = 'BTC') {
    if (value === undefined || value === '' || value === null) {
      return '0'
    }

    const precision = getPrecision(symbol, 'withdrawal')
    const numberValue = typeof value === 'string' ? parseFloat(value) : value
    const rounded = roundCurrency(numberValue, -precision, 'floor')

    return rounded.toLocaleString(locale.value, {
      maximumFractionDigits: precision
    })
  }

  // кол-во в паре, округляем вниз
  const formatBase = (value: TValue, symbol: TCurrency = 'BTC') => {
    if (value === undefined || value === '' || value === null) {
      return '0'
    }

    const precision = getPrecision(symbol, 'base')
    const numberValue = typeof value === 'string' ? parseFloat(value) : value
    const rounded = roundCurrency(numberValue, -precision, 'floor')

    return rounded.toLocaleString(locale.value, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision
    })
  }

  // цена в паре, округляем вверх
  const formatQuote = (value: TValue, symbol: TCurrency = 'BTC') => {
    if (value === undefined || value === '' || value === null) {
      return '0'
    }

    const precision = getPrecision(symbol, 'quote')
    const numberValue = typeof value === 'string' ? parseFloat(value) : value
    const rounded = roundCurrency(numberValue, -precision, 'ceil')

    return rounded.toLocaleString(locale.value, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision
    })
  }

  // цена в паре, округляем вверх
  function formatLending(value: TValue, symbol: TCurrency = 'BTC') {
    if (value === undefined || value === '' || value === null) {
      return '0'
    }

    const precision = getPrecision(symbol, 'lending')
    const numberValue = typeof value === 'string' ? parseFloat(value) : value
    const rounded = roundCurrency(numberValue, -precision, 'floor')

    return rounded.toLocaleString(locale.value, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision
    })
  }

  // цена в паре, округляем вверх
  function formatBorrowing(value: TValue, symbol: TCurrency = 'BTC') {
    if (value === undefined || value === '' || value === null) {
      return '0'
    }

    const precision = getPrecision(symbol, 'borrowing')
    const numberValue = typeof value === 'string' ? parseFloat(value) : value
    const rounded = roundCurrency(numberValue, -precision, 'ceil')

    return rounded.toLocaleString(locale.value, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision
    })
  }

  function formatByPrecision(value: TValue, precision?: number | undefined) {
    if (precision === undefined) {
      precision = 2
    }

    if (value === undefined || value === '' || value === null) {
      return '0'
    }

    const numberValue = typeof value === 'string' ? parseFloat(value) : value
    const rounded = roundCurrency(numberValue, -precision, 'floor')

    return rounded.toLocaleString(locale.value, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision
    })
  }

  function findBySymbol(symbol: string | undefined) {
    if (!symbol) {
      return
    }

    return items.value.find(item => item.symbol === symbol)
  }

  function getCurrencyDecimalPlaces(symbol: TCurrency, deft: number) {
    const currency = findBySymbol(symbol)

    if (!currency) {
      return deft
    }

    return currency.decimal_places
  }

  function getCurrencyById(id: number) {
    return items.value.find(item => item.id === id)
  }

  function currencyName(market: IIMarket | string) {
    let name = ''

    if (typeof market === 'object' && 'name' in market) {
      name = market.name
    } else {
      name = market
    }

    const [baseCurrencies, quoteCurrencies] = name.split('/')

    const baseCurrenciesName = findBySymbol(baseCurrencies)?.name
    const quoteCurrenciesName = findBySymbol(quoteCurrencies)?.name

    return `${baseCurrenciesName}/${quoteCurrenciesName}`
  }

  function formatInteger(value: string | number | undefined) {
    if (value === undefined || value === '' || value === null) {
      return '0'
    }

    return value.toLocaleString(locale.value)
  }

  async function fetch(params?: TFetcherParams) {
    itemsOriginal.value = await fetcher(params)
  }

  if (forceFetch) {
    useWatchAuthenticated(fetch)
  }

  return {
    itemsOriginal,
    items,
    fetch,
    activeItems,
    activeTransferItems,
    activeDepositEnabledItems,
    depositItems,
    availableBorrows,
    availablePledge,
    availableLending,
    currencyIdMap,
    currencySymbolMap,
    currencySymbolForURLMap,
    fiatItems,
    activeInvoiceEnabledItems,
    activeWithdrawEnabledItems,
    cryptoItems,
    networks,

    formatAmount,
    formatBalance,
    formatDeposit,
    formatWithdrawal,
    formatBase,
    formatQuote,
    formatLending,
    formatBorrowing,
    currencyName,
    findBySymbol,
    formatInteger,
    getCurrencyById,
    formatByPrecision,
    roundCurrency,
    locale,
    precisions,
    getPrecision
  }
}
